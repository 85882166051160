export const aggregationLogic: Record<
  number,
  { IG1: string[]; IG2: string[]; IG3: string[] }
> = {
  1: {
    IG1: ["1.4", "1.6"],
    IG2: ["1.1", "1.3", "1.5", "1.7"],
    IG3: ["1.1", "1.2", "1.3", "1.4", "1.5", "1.6", "1.7", "1.8"],
  },
  2: {
    IG1: ["2.1", "2.2", "2.6"],
    IG2: ["2.1", "2.2", "2.3", "2.4", "2.6"],
    IG3: [
      "2.1",
      "2.2",
      "2.3",
      "2.4",
      "2.5",
      "2.6",
      "2.7",
      "2.8",
      "2.9",
      "2.10",
    ],
  },
  3: {
    IG1: ["3.4", "3.5"],
    IG2: ["3.1", "3.2", "3.3", "3.4", "3.5", "3.6", "3.7"],
    IG3: ["3.1", "3.2", "3.3", "3.4", "3.5", "3.6", "3.7"],
  },
  4: {
    IG1: ["4.2", "4.3"],
    IG2: ["4.1", "4.2", "4.3", "4.4", "4.5", "4.7", "4.8", "4.9"],
    IG3: ["4.1", "4.2", "4.3", "4.4", "4.5", "4.6", "4.7", "4.8", "4.9"],
  },
  5: {
    IG1: ["5.1"],
    IG2: ["5.1", "5.2", "5.3", "5.4", "5.5"],
    IG3: ["5.1", "5.2", "5.3", "5.4", "5.5"],
  },
  6: {
    IG1: ["2.6"],
    IG2: ["6.1", "6.2", "6.3", "6.4", "6.5", "6.6", "6.7"],
    IG3: ["6.1", "6.2", "6.3", "6.4", "6.5", "6.6", "6.7", "6.8"],
  },
  7: {
    IG1: ["7.1", "7.7"],
    IG2: ["7.1", "7.2", "7.3", "7.4", "7.5", "7.6", "7.7", "7.8", "7.9"],
    IG3: [
      "7.1",
      "7.2",
      "7.3",
      "7.4",
      "7.5",
      "7.6",
      "7.7",
      "7.8",
      "7.9",
      "7.10",
    ],
  },
  8: {
    IG1: ["8.2", "8.4", "8.5"],
    IG2: ["8.1", "8.2", "8.3", "8.4", "8.5", "8.6", "8.7", "8.8"],
    IG3: ["8.1", "8.2", "8.3", "8.4", "8.5", "8.6", "8.7", "8.8"],
  },
  9: {
    IG1: ["9.4"],
    IG2: ["9.1", "9.2", "9.3", "9.4"],
    IG3: ["9.1", "9.2", "9.3", "9.4", "9.5"],
  },
  10: {
    IG1: ["10.1", "10.2", "10.4", "10.5"],
    IG2: ["10.1", "10.2", "10.3", "10.4", "10.5"],
    IG3: ["10.1", "10.2", "10.3", "10.4", "10.5"],
  },
  11: {
    IG1: ["11.4"],
    IG2: ["11.1", "11.2", "11.3", "11.4", "11.5", "11.6", "11.7"],
    IG3: ["11.1", "11.2", "11.3", "11.4", "11.5", "11.6", "11.7"],
  },
  12: {
    IG1: ["12.1", "12.4"],
    IG2: ["12.1", "12.2", "12.3", "12.4", "12.5", "12.6", "12.8", "12.11"],
    IG3: [
      "12.1",
      "12.2",
      "12.3",
      "12.4",
      "12.5",
      "12.6",
      "12.7",
      "12.8",
      "12.9",
      "12.10",
      "12.11",
      "12.12",
    ],
  },
  13: {
    IG1: ["13.1", "13.2", "13.6"],
    IG2: ["13.1", "13.2", "13.4", "13.6", "13.7"],
    IG3: [
      "13.1",
      "13.2",
      "13.3",
      "13.4",
      "13.5",
      "13.6",
      "13.7",
      "13.8",
      "13.9",
    ],
  },
  14: {
    IG1: ["14.6"],
    IG2: ["14.1", "14.2", "14.3", "14.4", "14.6"],
    IG3: [
      "14.1",
      "14.2",
      "14.3",
      "14.4",
      "14.5",
      "14.6",
      "14.7",
      "14.8",
      "14.9",
    ],
  },
  15: {
    IG1: ["15.7", "15.10"],
    IG2: ["15.1", "15.2", "15.3", "15.6", "15.7", "15.9", "15.10"],
    IG3: [
      "15.1",
      "15.2",
      "15.3",
      "15.4",
      "15.5",
      "15.6",
      "15.7",
      "15.8",
      "15.9",
      "15.10",
    ],
  },
  16: {
    IG1: ["16.8", "16.9", "16.11"],
    IG2: [
      "16.1",
      "16.2",
      "16.3",
      "16.4",
      "16.5",
      "16.6",
      "16.7",
      "16.8",
      "16.9",
      "16.10",
      "16.11",
      "16.12",
    ],
    IG3: [
      "16.1",
      "16.2",
      "16.3",
      "16.4",
      "16.5",
      "16.6",
      "16.7",
      "16.8",
      "16.9",
      "16.10",
      "16.11",
      "16.12",
      "16.13",
    ],
  },
  17: {
    IG1: ["17.3", "17.5", "17.6", "17.7", "17.8", "17.9"],
    IG2: [
      "17.1",
      "17.2",
      "17.3",
      "17.4",
      "17.5",
      "17.6",
      "17.7",
      "17.8",
      "17.9",
    ],
    IG3: [
      "17.1",
      "17.2",
      "17.3",
      "17.4",
      "17.5",
      "17.6",
      "17.7",
      "17.8",
      "17.9",
    ],
  },
  18: {
    IG1: [],
    IG2: [
      "18.1",
      "18.2",
      "18.3",
      "18.5",
      "18.6",
      "18.7",
      "18.8",
      "18.9",
      "18.10",
      "18.11",
    ],
    IG3: [
      "18.1",
      "18.2",
      "18.3",
      "18.4",
      "18.5",
      "18.6",
      "18.7",
      "18.8",
      "18.9",
      "18.10",
      "18.11",
    ],
  },
  19: {
    IG1: ["19.1", "19.3", "19.5", "19.6"],
    IG2: ["19.1", "19.2", "19.3", "19.4", "19.5", "19.6", "19.7"],
    IG3: ["19.1", "19.2", "19.3", "19.4", "19.5", "19.6", "19.7", "19.8"],
  },
  20: {
    IG1: [],
    IG2: ["20.1", "20.2", "20.4", "20.5", "20.6", "20.8"],
    IG3: ["20.1", "20.2", "20.3", "20.4", "20.5", "20.6", "20.7", "20.8"],
  },
};
