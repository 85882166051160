import {
  NotificationsPlugin,
  NotificationsPluginState,
} from "@pimo/notifications-plugin";
import {
  App,
  composeInitialState,
  LoginPlugin,
  LoginPluginPartialAppState,
  UniversalAppState,
} from "@pimo/pimo-app-builder";
import {
  DefaultOverlayPartialAppState,
  initialDefaultOverlayState,
  type Log,
} from "@pimo/pimo-components";
import type {
  Currency,
  FilterData,
  OE,
  OEOverviewResponse,
  Profile,
  Program,
  QuestionnaireResponse,
} from "crq-types";

import { APP_KEY } from "../app-key";
import type { CRQLineChartCardEventMap } from "./components/crq-line-chart";
import type { CRQQuestionnaireDialogType } from "./components/crq-questionnaire-dialog";
import { APP_ROUTES } from "./constants";
import { STRAPI_URL } from "./env";
import {
  fetchOEOverview,
  fetchOEs,
  fetchProgram,
} from "./helpers/fetch-helpers";
import { createOverlay } from "./overlay";
import { GroupDashboardPlugin } from "./pages/group-dashboard-plugin";
import { OEOverviewPlugin } from "./pages/oe-overview-plugin";
import { OEReportPlugin } from "./pages/oe-report-plugin";
import { SettingsPlugin } from "./pages/settings-plugin";
import { theme } from "./theme";
export type CRQAppState = DefaultOverlayPartialAppState &
  LoginPluginPartialAppState<Profile> &
  NotificationsPluginState &
  UniversalAppState & {
    currentCurrency?: Currency;
    currentOE?: OE;
    currentQuestionnaire?: QuestionnaireResponse;
    currentScale: CRQLineChartCardEventMap["change-time"]["scale"];
    currentYear: CRQLineChartCardEventMap["change-time"]["year"];
    filterDialogData?: FilterData;
    filterDialogOptions?: Partial<FilterData>;
    OEs: OE[];
    OEOverview?: OEOverviewResponse;
    program?: Program;
    showQuantificationDialog: boolean;
    showQuestionnaireDialog: boolean;
    questionnaireDialogType?: CRQQuestionnaireDialogType;
    logs?: Log[];
  };

// Plugins
const loginPlugin = new LoginPlugin<Profile, CRQAppState>(
  APP_ROUTES.dashboard,
  STRAPI_URL,
  "CRQ"
);

const groupDashboardPlugin = new GroupDashboardPlugin<CRQAppState>();
const oeOverviewPlugin = new OEOverviewPlugin();
const oeReportPlugin = new OEReportPlugin();
const settingsPlugin = new SettingsPlugin();
const notificationsPlugin = new NotificationsPlugin<CRQAppState>(STRAPI_URL);

// App
const app = App.create<CRQAppState>(
  composeInitialState<CRQAppState>({
    ...initialDefaultOverlayState,
    ...loginPlugin.getInitialState(),
    currentScale: "monthly",
    currentYear: new Date().getFullYear(),
    isLoading: false,
    isSidebarOpen: true,
    OEs: [],
    showQuantificationDialog: false,
    showQuestionnaireDialog: false,
  }),
  APP_KEY
);
app.setTheme(theme);

// Overlay
const overlay = createOverlay(app);

overlay.on("overlay:logout", () => {
  loginPlugin.logout();
});

loginPlugin.on("login-status-changed", async (evt) => {
  if (!evt.payload?.isLoggedIn) {
    return;
  }

  const [OEs, OEOverview, program] = await Promise.all([
    fetchOEs(),
    fetchOEOverview(),
    fetchProgram(),
  ]);

  app.patchAppState({
    OEs,
    OEOverview,
    program,
    filterDialogOptions: {
      names: OEs.filter(({ name }) => Boolean(name)).map(
        ({ name }) => name
      ) as string[],
      regions: OEOverview.regions ?? [],
    },
  });
});

app.registerPlugin(loginPlugin);
app.registerPlugin(groupDashboardPlugin);
app.registerPlugin(oeOverviewPlugin);
app.registerPlugin(oeReportPlugin);
app.registerPlugin(notificationsPlugin);
app.registerPlugin(settingsPlugin);

const RenderedApp = app.render();

export default RenderedApp;
