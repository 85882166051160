import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  type GridProps,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { Layout, PimoReactLayout } from "@pimo/pimo-app-builder";
import { LayoutSideBar } from "@pimo/pimo-components";
import { useParams } from "react-router-dom";

import { SideMenuEntryInterface } from "../types";

export type SettingsGridLayoutProps = {
  viewname?: string;
} & GridProps;

const Stage = styled(Box, {
  shouldForwardProp: (prop) => prop !== "enablePageBreak",
})<{ enablePageBreak: boolean }>(({ theme, enablePageBreak }) => ({
  flexGrow: 1,
  ...theme.mixins.toolbar,
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(1.5),
  "@media print": {
    backgroundColor: "unset",
    pageBreakBefore: enablePageBreak ? "always" : "avoid",
  },
}));

const buildGridLayoutReactComponent: (
  spacing: number,
  enablePageBreak: boolean,
  workspaceRoute: string,
  entries: SideMenuEntryInterface[]
) => PimoReactLayout<SettingsGridLayoutProps> =
  (spacing, enablePageBreak, workspaceRoute, entries) =>
  ({ components }) => {
    const theme = useTheme();
    const params = useParams();

    const settingsContentComponents = components.filter(
      (component) => component.layoutProps?.viewname === params.viewname
    );

    return (
      <Grid
        container
        sx={{
          minHeight: "100vh",
        }}
        style={{
          margin: "-24px",
          paddingRight: "0",
          width: "calc(100% + 48px)",
          height: "calc(100% + 48px)",
        }}
      >
        <Grid
          item
          xs={3}
          sx={{
            backgroundColor: theme.palette.secondary.main,
            height: "100vh",
            padding: theme.spacing(2),
          }}
        >
          <Button
            onClick={() => {
              window.location.href = workspaceRoute;
            }}
            sx={{
              color: theme.palette.primary.main,
              width: "100%",
              marginBottom: theme.spacing(4),
            }}
          >
            <ArrowBack sx={{ mr: 1 }} />
            Back to Workspace
          </Button>
          <LayoutSideBar entries={entries} />
        </Grid>
        <Grid
          item
          xs={9}
          sx={{
            backgroundColor: theme.palette.secondary.main,
            padding: theme.spacing(4),
          }}
        >
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginBottom: theme.spacing(2) }}
          >
            <Typography
              variant="h5"
              sx={{
                marginBottom: theme.spacing(2),
              }}
            >
              Settings
            </Typography>
          </Grid>

          <Stage
            enablePageBreak={enablePageBreak}
            sx={{
              padding: theme.spacing(spacing),
              mb: theme.spacing(spacing),
            }}
          >
            <Grid container spacing={spacing}>
              {settingsContentComponents.map((component, index) => {
                const Component = component?.render?.();
                return (
                  <Grid
                    item
                    key={`grid_item_${index}`}
                    {...component.layoutProps}
                  >
                    <Component />
                  </Grid>
                );
              })}
            </Grid>
          </Stage>
        </Grid>
      </Grid>
    );
  };

export class SettingsLayout implements Layout<SettingsGridLayoutProps> {
  private spacing: number;
  private enablePageBreak: boolean;
  private workspaceRoute: string;
  private entries: SideMenuEntryInterface[];

  constructor({
    spacing = 1,
    enablePageBreak = false,
    workspaceRoute,
    entries,
  }: {
    spacing?: number;
    enablePageBreak?: boolean;
    workspaceRoute: string;
    entries: SideMenuEntryInterface[];
  }) {
    this.spacing = spacing;
    this.enablePageBreak = enablePageBreak;
    this.workspaceRoute = workspaceRoute;
    this.entries = entries;
  }

  getLayoutComponent() {
    return buildGridLayoutReactComponent(
      this.spacing,
      this.enablePageBreak,
      this.workspaceRoute,
      this.entries
    );
  }
}

export default SettingsLayout;
