import type { App, Plugin, Route, View } from "@pimo/pimo-app-builder";
import { APP_ROUTES } from "../constants";
import {
  TwoColumnGridLayout,
  TwoColumnGridLayoutProps,
} from "@pimo/pimo-components";
import { CRQAppState } from "../app";
import { SettingsOverviewTitleCard } from "../components/settings-overview-title-card/settings-overview-title-card";
import { NotificationsPlugin } from "@pimo/notifications-plugin";

// eslint-disable-next-line
export interface SettingsPluginState {}

export class SettingsPlugin
  implements Plugin<CRQAppState, SettingsPluginState, "viewname">
{
  route?: Route<"viewname", CRQAppState>;
  private settingsView?: View<CRQAppState, TwoColumnGridLayoutProps>;
  private app?: App<CRQAppState>;

  onRegister(app: App<CRQAppState>): void {
    this.app = app;

    const entries = [
      {
        link: "/settings/manage-OEs",
        title: "Manage OEs",
        viewname: "manage-OEs",
      },
      {
        link: "/settings/notifications",
        title: "Notifications",
        viewname: "notifications",
      },
      {
        link: "/settings/general",
        title: "General Settings",
        viewname: "general",
      },
    ];

    this.settingsView = app.createView({
      name: "Settings",
      layout: new TwoColumnGridLayout({
        spacing: 1,
        enablePageBreak: false,
        workspaceRoute: APP_ROUTES.overview,
        entries,
        title: "Settings",
      }),
    });

    this.buildRoute();

    this.buildManageOEsView();
    this.buildNotificationsView();
    this.buildGeneralSettingsView();
  }

  private buildRoute() {
    if (!this.app || !this.settingsView) return;

    this.route = this.app.createRoute<"viewname">({
      path: "/settings/:viewname",
      view: this.settingsView,
    });

    this.route.on("load", () => {
      this.app?.patchAppState({
        isSidebarOpen: false,
      });
    });
  }

  private buildManageOEsView() {
    const manageOEsView = this.settingsView?.addComponent({
      component: SettingsOverviewTitleCard,
      layoutProps: { viewname: "manage-OEs", xs: 12 },
    });

    manageOEsView?.mapState(() => {
      return {
        title: "Manage OEs",
      };
    });
  }

  private buildNotificationsView() {
    if (!this.settingsView || !this.route) return;

    const notificationsView = this.settingsView?.addComponent({
      component: SettingsOverviewTitleCard,
      layoutProps: { viewname: "notifications", xs: 12 },
    });

    notificationsView?.mapState(() => {
      return {
        title: "Notifications",
      };
    });

    const notificationsPlugin = this.app?.getPluginByName<
      NotificationsPlugin<CRQAppState>
    >("NotificationsPlugin");

    notificationsPlugin?.renderNotificationsManager({
      view: this.settingsView,
      route: this.route as Route,
      layoutProps: { viewname: "notifications", xs: 12 },
    });
  }

  private buildGeneralSettingsView() {
    const generalSettingsView = this.settingsView?.addComponent({
      component: SettingsOverviewTitleCard,
      layoutProps: { viewname: "general", xs: 12 },
    });

    generalSettingsView?.mapState(() => {
      return {
        title: "General Settings",
      };
    });
  }
}
